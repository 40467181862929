import React, { useEffect, useState } from "react";
import { htmlPrint } from "./htmlPrinter";
import { xmlPrint } from "./xmlPrinter";
import HCPrint from "./hardWareControllerPrinter";
import { kotPrinter } from "./kotPrinter";
import { v4 as uuidv4 } from "uuid";


const PrintController = (receiptData, cart) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const ObFlag = tillData.tillAccess.cwrTill.printTemplate.obController === "Y" ? true : false;
  const isPrintModeXML = tillData.tillAccess.cwrTill.hardwareController.printReceipt === "Y" ? true : false;
  const defaultCustomer = tillData.tillAccess.csBunit.b2cCustomer;
  if (localStorage.getItem("dineIn") === "N") {
    const printKOTArray = cart.items.reduce((result, current) => {
      const category = current.productionCenter;
      if (!result[category]) {
        result[category] = [];
      }
      result[category].push(current);
      return result;
    }, {});
    kotPrinter(printKOTArray, cart, "receipt");
    setTimeout(() => {
      if (ObFlag) {
        HCPrint(receiptData);
      } else if (isPrintModeXML) {
        xmlPrint(receiptData, "Receipt");
      } else {
        htmlPrint(receiptData, "Receipt");
      }
    }, 1000);
  } else {
    if (ObFlag) {
      HCPrint(receiptData);
    } else if (isPrintModeXML) {
      xmlPrint(receiptData, "Receipt");
    } else {
      htmlPrint(receiptData, "Receipt");
    }
  }
}
export default PrintController; 
