import React, { useEffect, useRef } from "react";
import { Row, Col, Button, Form } from "antd";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "./style.css";
import "./mobile.css";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Logo from "../../../../assets/images/kioskLogo.svg";
import mobileImg from "../../../../assets/images/mobile.svg";
import { LeftCircleOutlined } from "@ant-design/icons";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import backImg from "../../../../assets/images/backImg.svg";

import { useTranslation } from "react-i18next";

const MobileComponent = (props) => {
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual");
  const {
    setKioskUI,
    layoutName,
    setLayoutName,
    onChange,
    handleKeyboardInputs,
    keyboardRef,
    inputName,
    setInputFields,
    kioskLogin,
    inputRef,
    setInputName,
    handleCustomerSearch,
    setCustomerSearchInput,
  } = props;

  useEffect(()=>{
    kioskLogin.resetFields()
    setInputFields({})
  },[])
  return (
    <div style={{ height: "100vh" }}>
      <div className="customer-login">
        <img src={kisokLogo} style={{ height: "2.6vw", position: "relative", top: "2.3vh", left: "1.2vw" }} />
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "15.3vw" }}></div>
        <div className="mc-main-div">
          <div style={{ position: "relative" }}>
            <div className="mc-left-div">
              <img src={mobileImg} alt="mobile" className="mc-cl-img" />
            </div>
            <Form layout="vertical" form={kioskLogin} name="kioskLogin">
              <Form.Item
                onFocus={() => {
                  setInputName("mobile");
                  setLayoutName("default");
                }}
                ref={inputRef}
                name={"mobile"}
                label={<p style={{ margin: "0", color: "#fff", fontSize: "1.6vw" }}>Mobile Number</p>}
              >
                <input
                  inputMode="none"
                  className="mc-input-field"
                  autoFocus={true}
                  onChange={(e) => {
                    setInputFields({ mobile: e.target.value });
                    // setCustomerSearchInput(e.target.value);
                    kioskLogin.setFieldsValue({ mobile: e.target.value });
                  }}
                />
              </Form.Item>
            </Form>
            {/* <p style={{ margin: "0", color: "#fff", fontSize: "1.6vw", paddingBottom: "0.3vh" }}>Mobile Number</p> */}

            <div className="mc-input-lable"></div>
            <div style={{ height: "1vh", fontSize: "2.5vh", color: "red" }}></div>
          </div>
          <Button className="mc-continue-btn" onClick={handleCustomerSearch}>
            Continue
          </Button>
        </div>
        <div style={{ width: "11.5vw" }}></div>
        <div className="mc-kb-div">
          <Keyboard
            keyboardRef={(r) => (keyboardRef.current = r)}
            inputName={inputName}
            onChangeAll={(inputs) => {
              setInputFields({ ...inputs });
              // setCustomerSearchInput(inputs.mobile);
              kioskLogin.setFieldsValue(inputs);
            }}
            onKeyPress={handleKeyboardInputs}
            layoutName={layoutName}
            theme={"hg-theme-default mobile"}
            buttonTheme={[
              {
                class: "hg-bksp",
                buttons: "{bksp} {backspace}",
              },
            ]}
            layout={{
              default: ["1 2 3", "4 5 6", "7 8 9", ". 0 {bksp}"],
            }}
          />
        </div>
        <div></div>
      </div>
      <div className="left-bottom-images">
        <div
          className="bottom-img"
          onClick={() => {
            localStorage.setItem("kioskUI",1);
            setKioskUI(1);
            const customEvent = new CustomEvent("customStorageChange", {
              detail: { key: "kioskUI", newValue: 1 },
            });
            window.dispatchEvent(customEvent);
          }}
        >
          <img src={backImg} style={{ height: "2.7vw" }} />
          <p style={{ color: "#fff", margin: "0" }}>Back</p>
        </div>
        <div className="bottom-img">
          <img src={volumeImg} style={{ height: "2.7vw" }} />
          <p style={{ color: "#fff", margin: "0" }}>Volume</p>
        </div>
        <div className="bottom-img">
          <img src={wheelchairImg} style={{ height: "2.7vw" }} />
          <p style={{ color: "#fff", margin: "0" }}>Accessibility</p>
        </div>
        <div className="bottom-img">
          <img src={helpImg} style={{ height: "2.7vw" }} />
          <p style={{ color: "#fff", margin: "0" }}>Help</p>
        </div>
      </div>
    </div>
  );
};

export default MobileComponent;
