import { SyncData } from "../../Restaurant/Tables-Management/SyncData";
import { sendOrder } from "../../../../socket";
import db from "../../../../database";

export const CheckoutTotalManualDiscount = async (discountParam, setCart, cart, manualDiscountTypes, selectedManualDiscountType, manualDiscountInput, tillaccess) => {
  const billCart = { ...cart };
  const pricingRule = manualDiscountTypes[manualDiscountTypes.findIndex((md) => md.mPricingrulesId === selectedManualDiscountType)];
  if (pricingRule.discountType === "V" || pricingRule.discountType === "P") {
    let discountValue = 0;
    discountValue = pricingRule.discountType === "V" ? parseFloat(manualDiscountInput) : (parseFloat(manualDiscountInput) / 100) * billCart.total;
    discountValue = discountParam ? parseFloat(discountParam) : discountValue;
    const cartItemsFiltered = billCart.items.filter((i) => i.nextRule === "Y" || i.nextRule === undefined);
    let salePricesTotal = 0;
    for (let i = 0; i < cartItemsFiltered.length; i += 1) {
      salePricesTotal += parseFloat(cartItemsFiltered[i].realPrice);
    }

    billCart.items.map((addedToCart, index) => {
      if (addedToCart.nextRule === "Y" || addedToCart.nextRule === undefined) {
        const discountAmt = parseFloat((addedToCart.realPrice / salePricesTotal) * discountValue) / addedToCart.weight;
        const sp = parseFloat(addedToCart.realPrice) - discountAmt;
        const mrp = parseFloat(sp) * addedToCart.weight;
        const tax = mrp - mrp / (1 + addedToCart.taxRate / 100);
        addedToCart.salePrice = sp;
        addedToCart.taxAmount = tax;
        addedToCart.nettotal = mrp;
        addedToCart.discount = discountAmt * addedToCart.weight;
        addedToCart.discountName = pricingRule.printedName;
        addedToCart.mPricingruleId = pricingRule.mPricingrulesId;
        billCart.items[index] = addedToCart;
      }
    });

    const addedToCart = billCart.items;

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < addedToCart.length; i += 1) {
      totalPrice += addedToCart[i].nettotal;
      totalItemsQty += addedToCart[i].weight;
      totalTax += addedToCart[i].taxAmount;
      totalDiscounts += addedToCart[i].discount;
      addedToCart[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;
    if (tillaccess?.layout === "2" && localStorage.getItem("dineIn") === "Y") {
      let obj;
      const fbOrderData = JSON.parse(localStorage.getItem("tableName"));
      db.fbOrderData
        .where("cwrFbTableId")
        .equals(fbOrderData?.cwrFbTableId)
        .toArray()
        .then((ordersFetched) => {
          if (ordersFetched.length > 0) {
            ordersFetched.map(async (fbOrder) => {
              if (fbOrder.fbOrderStatus === "IP") {
                let orderLines = [];
                fbOrder.cart = {
                  ...cart,
                  items: [...addedToCart],
                  total: totalPrice,
                  tax: totalTax,
                  discount: totalDiscounts,
                  totalQty: totalItemsQty,
                  roundOff: totalRoundOff,
                };
                fbOrder.fbOrderSync = "N";
                fbOrder.lines = orderLines;
                addedToCart.map((obj) => {
                  orderLines.push(`{
                  fbOrderId: "${fbOrder.fbOrderId}"
                  fbOrderLineId: "${obj.fbOrderLineId}"
                  mPoductId: "${obj.productId}"
                  mBatchId: null
                  description: "good"
                  csUomId: "${obj.uom}"
                  csTaxId: "${obj.tax}"
                  discount:${obj.discount}
                  line: 1
                  qty: ${obj.weight}
                  unitPrice: ${obj.realPrice}
                  listPrice: 30
                  lineNet: 2.6
                  lineTax: ${obj.taxRate}
                  lineGross: 30
                  sOrderLineId: null
                  isOrdered: "Y"
                  meta:[]
                  }`);
                });
                await db.fbOrderData.put(fbOrder, fbOrder.fbOrderId);
                obj = {
                  fbOrder: {
                    fbOrderId: fbOrder.fbOrderId,
                    order: fbOrder,
                  },
                };
                // sendOrder(obj);
              }
            });
          }
        });
      SyncData(fbOrderData, "upsertFbOrder");
    }
    let cartObj = {
      ...cart,
      items: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
      manualDiscountApplied: discountValue,
    };
    setCart(cartObj);
    localStorage.setItem("cartObj", JSON.stringify(cartObj));
  }
};
