import React, { useEffect } from "react";
import { Row, Col, Button, Input, Form } from "antd";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "./style.css";
import "./email.css";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Logo from "../../../../assets/images/kioskLogo.svg";
import Email from "../../../../assets/images/email.svg";
import { LeftCircleOutlined } from "@ant-design/icons";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import backImg from "../../../../assets/images/backImg.svg";

const EmailComponent = (props) => {
  const {
    setKioskUI,
    layoutName,
    setLayoutName,
    onChange,
    handleKeyboardInputs,
    keyboardRef,
    inputName,
    setInputFields,
    kioskLogin,
    inputRef,
    setInputName,
    handleCustomerSearch,
    setCustomerSearchInput,
    changeLoginType,
  } = props;
  //   const { t } = useTranslation();

    useEffect(()=>{
      kioskLogin.resetFields()
      setInputFields({})
     },[])
  return (
    <div style={{ height: "100vh" }}>
      <div className="customer-login">
      </div>
      <div style={{height:"9vh",display:"flex",justifyContent:"space-between"}}>
        <img
          src={kisokLogo}
          style={{ height: "2.6vw", position: "relative", top: "2.3vh", left: "1.2vw" ,cursor:"pointer"}}

        />
        <img src={backImg} style={{paddingRight:"2vw",cursor:"pointer"}} onClick={() => {
            setKioskUI(2);
            const customEvent = new CustomEvent("customStorageChange", {
              detail: { key: "kioskUI", newValue: 2 },
            });
            window.dispatchEvent(customEvent);
            localStorage.setItem("kioskUI",2);
          }}/>
      </div>
      <div style={{ display: "flex", justifyContent: "end", alignItems: "center", flexDirection: "column", height: "50vh", paddingBottom: "3vh" }}>
        <div>
          <img src={Email} alt="email" className="email-icon" />
        </div>
        <Form layout="vertical" form={kioskLogin} name="kioskLogin">
          <Form.Item
            onFocus={() => {
              setInputName("email");
              setLayoutName("default");
            }}
            ref={inputRef}
            name={"email"}
            label={<p style={{ margin: "0", color: "#fff", fontSize: "1.6vw" }}>Email Address</p>}
           >
            <input
              inputMode="none"
              className="mc-input-field"
              autoFocus={true}
              onChange={(e) => {
                setInputFields({ email: e.target.value });
                // setCustomerSearchInput(e.target.value);
                kioskLogin.setFieldsValue({ email: e.target.value });
              }}
            />
          </Form.Item>
        </Form>
        <div className="mc-input-lable"></div>
        <div style={{ height: "1vh", fontSize: "2.5vh", color: "red" }}></div>
        <Button className="email-continue-btn" onClick={handleCustomerSearch}>
          Continue
        </Button>
      </div>
      <div className="email-kb-div">
        <Keyboard
          buttonTheme={[
            {
              class: "hg-yellow",
              buttons: "{space}",
            },
            {
              class: "hg-space",
              buttons: "{spaces}",
            },
            {
              class: "hg-mail",
              buttons: "@yahoo.com @gmail.com",
            },
          ]}
          keyboardRef={(r) => (keyboardRef.current = r)}
          inputName={inputName}
          onChangeAll={(inputs) => {
            setInputFields({ ...inputs });
            // setCustomerSearchInput(inputs.email);
            kioskLogin.setFieldsValue(inputs);
          }}
          onKeyPress={handleKeyboardInputs}
          layoutName={layoutName}
          theme={"hg-theme-default email"}
          display={{
            "{abc}": "ABC",
            "{smileys}": "\uD83D\uDE03",
            "{numbers}": "123",
            "{number}": "123",
            "{bksp}": "backspace",
            "{backspace}": "⌫",
            "{shift}": "⇧",
            "{tab}": "tab",
            "{lock}": "lock",
            "{enter}": "enter",
            "{space}": "Space",
            "{spaces}": "Space",
            "{caps}": "ABC",
            "{small}": "abc",
            "{back}": "Enter",
          }}
          layout={{
            shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {bksp}", "{number} {space}"],
            numbers: ["1 2 3 4 5 6 7 8 9 0", '- / : ; ( ) $ & @ "', "# . , ? ! ` {bksp}", "{caps} {space}"],
            number: ["1 2 3 4 5 6 7 8 9 0", '- / : ; ( ) $ & @ "', "# . , ? ! ` {bksp}", "{small} {space}"],
            default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {bksp}", "{numbers} {space}"],
          }}
        />
      </div>
    </div>
  );
};

export default EmailComponent;
