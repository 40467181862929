import { SyncData } from "../../Restaurant/Tables-Management/SyncData";
import { sendOrder } from "../../../../socket";
import db from "../../../../database";

export const CheckoutFlatDiscount = async (cart,setCart,selectedRowKeys,pricingRule,manualDiscountInput,tillaccess) => {
    const addedToCart = cart.items;
    const index = selectedRowKeys[0];
    if (index >= 0 && !addedToCart[index].isReturn) {
      const discountAmt = parseFloat(manualDiscountInput);
      const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
      const mrp = parseFloat(sp) * addedToCart[index].weight;
      const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
      addedToCart[index].salePrice = sp;
      addedToCart[index].taxAmount = tax;
      addedToCart[index].nettotal = mrp;
      addedToCart[index].discount = discountAmt * addedToCart[index].weight;
      addedToCart[index].discountName = pricingRule.printedName;
      addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;

      let totalTax = 0;
      let totalPrice = 0;
      let totalItemsQty = 0;
      let totalDiscounts = 0;
      for (let i = 0; i < addedToCart.length; i += 1) {
        totalPrice += addedToCart[i].nettotal;
        totalItemsQty += addedToCart[i].weight;
        totalTax += addedToCart[i].taxAmount;
        totalDiscounts += addedToCart[i].discount;
        addedToCart[i].key = i;
      }

      const roundOffValue = Math.round(totalPrice);
      const totalRoundOff = totalPrice - roundOffValue;
      totalPrice = roundOffValue;

      if (tillaccess?.layout === "2" && localStorage.getItem("dineIn") === "Y") {
        let obj;
        const fbOrderData = JSON.parse(localStorage.getItem("tableName"));
         db.fbOrderData
        .where("cwrFbTableId")
        .equals(fbOrderData?.cwrFbTableId)
        .toArray()
        .then((ordersFetched) => {
          if(ordersFetched.length>0){
            ordersFetched.map(async(fbOrder) => {
              if(fbOrder.fbOrderStatus === "IP") {
                let orderLines = [];
                fbOrder.cart = {
                  ...cart,
                  items: [...addedToCart],
                  total: totalPrice,
                  tax: totalTax,
                  discount: totalDiscounts,
                  totalQty: totalItemsQty,
                  roundOff: totalRoundOff,
                }
                fbOrder.fbOrderSync = "N";
                fbOrder.lines = orderLines;
                addedToCart.map(obj => {
                  orderLines.push(`{
                    fbOrderId: "${fbOrder.fbOrderId}"
                    fbOrderLineId: "${obj.fbOrderLineId}"
                    mPoductId: "${obj.productId}"
                    mBatchId: null
                    description: "good"
                    csUomId: "${obj.uom}"
                    csTaxId: "${obj.tax}"
                    discount:${obj.discount}
                    line: 1
                    qty: ${obj.weight}
                    unitPrice: ${obj.realPrice}
                    listPrice: 30
                    lineNet: 2.6
                    lineTax: ${obj.taxRate}
                    lineGross: 30
                    sOrderLineId: null
                    isOrdered: "Y"
                    meta:[]
                    }`);
                })
                await db.fbOrderData.put(fbOrder, fbOrder.fbOrderId);
                obj = {
                  fbOrder: {
                    fbOrderId: fbOrder.fbOrderId,
                    order: fbOrder
                  }
                };
                // sendOrder(obj);
              }
            })
          }
        })
        SyncData(fbOrderData,"upsertFbOrder");
      }

      setCart({
        ...cart,
        items: [...addedToCart],
        total: totalPrice,
        tax: totalTax,
        discount: totalDiscounts,
        totalQty: totalItemsQty,
        roundOff: totalRoundOff,
      });
    }
}