import React, { useState, useEffect, useRef } from "react";
import { Row, Button, Col, Checkbox, Card, Input, Space, Typography, message } from "antd";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import { CloseOutlined, LoadingOutlined, StopOutlined, SearchOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import Back from "../../../assets/images/backIcon.svg";
import CancelArrow from "../../../assets/images/cancelArrow.svg";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import call from "../../../assets/images/fluentCall.svg";
import GroupImage from "../../../assets/images/person-group.svg";
import Email from "../../../assets/images/e-email.svg";
import Party from "../../../assets/images/party.svg";
import Time from "../../../assets/images/ic_round-access-time.svg";
import removeBg from "../../../assets/images/removebg.png";

import _ from "lodash";
import "./restaurantStyle.css";
import moment from "moment";
import db from "../../../database";

const CustomersComponent = ({
  cart,
  selectedTable,
  setSelectedTable,
  tillDataPaymentMethods,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  setIsCardPaymentFlag,
  processOrder,
  amount,
  onChangeAmount,
  paymentModalInputRef,
  setIsInputFocused,
  requestPayment,
  denaminationsKeyboard,
  handleCashPayment,
  handleAmount,
  setDenaminationsKeyboard,
  setNumb,
  setKeyValue,
  paymentVisible,
  setPaymentVisible,
  showOrderCompleteFlag,
  setShowOrderCompleteFlag,
}) => {
  const { Text, Title } = Typography;
  const [selectCustomerData, setSelectCustomerData] = useState({});
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const [tableOrderData, setTableOrderData] = useState([]);
  useEffect(() => {
    db.tableData.toArray().then((response) => {
      let finalData = response.filter((data) => data.statusType === "OCU");
      setTableOrderData(finalData);
    });
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: Object.keys(selectCustomerData).length > 0 ? "69%" : "100%",
          height: "100vh",
          padding: "2vh 1vw",
        }}
      >
        <div>
          <Row style={{ fontSize: "1vw", paddingLeft: "0.5vw" }}>
            <Col span={Object.keys(selectCustomerData).length > 0 ? 13 : 10}>
              <Input
                placeholder="Search for products by code/name"
                id="sm-product-search"
                onChange={(e) => {
                  // e.target.value === "" ? clearProductSearchResults() : onChangeInput(e);
                }}
                className="productSearch-sm"
                style={{
                  borderRadius: "20px",
                  borderColor: "#fff",
                }}
                // value={productSearchInput}
                // suffix={
                //   <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                //     {productSearchInput.length > 0 ? (
                //       <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856" }} onClick={() => clearProductSearchResults("")} />
                //     ) : null}
                //   </div>
                // }
                prefix={
                  <img
                    src={SearchIcon}
                    alt=""
                    onClick={() => {
                      // onBarcodeInput(productSearchInput, false);
                    }}
                  />
                }
              />
            </Col>
          </Row>
          <Scrollbars autoHide style={{ height: "90vh" }}>
            <InfiniteScroll
              dataLength={tableOrderData.length}
              hasMore={true}
              endMessage={
                <p className="center">
                  <b>Yay! you have seen it all</b>
                </p>
              }
              style={{ overflowX: "hidden" }}
            >
              <Row style={{ marginTop: "2vh",fontSize:"1vw" }}>
                {tableOrderData.map((orderData) => {
                  return (
                    <Col span={Object.keys(selectCustomerData).length > 0 ? 12 : 8} style={{ padding: "1vh 0.6vw" }}>
                      <Card
                        onClick={() => setSelectCustomerData(orderData)}
                        style={{
                          backgroundColor: "#fff",
                          padding: "1vh 0.7vw",
                          borderRadius: "10px",
                          height: "19vh",
                          fontSize: "1vw",
                          cursor: "pointer",
                        }}
                       >
                        <Space
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                            paddingLeft: "7px",
                            paddingRight: "7px",
                          }}
                         >
                          <Text
                            style={{
                              fontSize: "1.25em",
                              fontWeight: 600,
                              color: "#0F0718",
                              fontFamily: "Inter",
                            }}
                          >
                            Order {orderData?.guestName || null}
                          </Text>
                          <Text
                            style={{
                              fontSize: "1.25em",
                              fontWeight: 600,
                              color: "#0F0718",
                              fontFamily: "Inter",
                            }}
                          >
                            Order {orderData?.documentno || null}
                            {/* {moment(orderData.time).format("HH:mm:ss")} */}
                          </Text>
                        </Space>
                        <Space
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                            paddingLeft: "7px",
                            paddingRight: "7px",
                          }}
                         >
                          <Text
                            style={{
                              fontSize: "1em",
                              fontWeight: 500,
                              color: "#0F0718",
                              fontFamily: "Inter",
                            }}
                          >
                            {orderData.name} . {orderData.sectionName}
                          </Text>
                          <Text
                            style={{
                              fontSize: "1em",
                              fontWeight: 500,
                              color: "#929098",
                              fontFamily: "Inter",
                            }}
                           >
                            {moment(orderData.time).format("HH:mm")}
                          </Text>
                        </Space>
                        <Space
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                            paddingLeft: "7px",
                            paddingRight: "7px",
                          }}
                         >
                          <Text
                            style={{
                              fontSize: "1em",
                              fontWeight: 500,
                              color: "#0F0718",
                              fontFamily: "Inter",
                            }}
                           >
                            Ordered Items: {orderData?.items?.length || 0}
                          </Text>
                          <Text
                            style={{
                              fontSize: "1.25em",
                              fontWeight: 500,
                              color: "#0F0718",
                              fontFamily: "Inter",
                            }}
                           >
                            {/* Amount: {orderData?.totalAmount || 0} */}
                          </Text>
                        </Space>
                        <Space
                          style={{
                            width: "100%",
                            justifyContent: "space-between",
                            paddingLeft: "7px",
                            paddingRight: "7px",
                            paddingTop:"0.5vh"
                           }}
                         >
                          <span
                            style={{
                              fontSize: "0.8em",
                              fontWeight: 500,
                              color: "#2F3856",
                              fontFamily: "Inter",
                              padding: "1vh 1vw",
                              backgroundColor: "#89CFF0",
                              borderRadius: "3px",
                            }}
                           >
                            In Progress
                          </span>
                          <Text
                            style={{
                              fontSize: "1.25em",
                              fontWeight: 600,
                              color: "#0F0718",
                              fontFamily: "Inter",
                            }}
                           >
                            Amount: {orderData?.totalAmount || 0}
                          </Text>
                        </Space>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </InfiniteScroll>
          </Scrollbars>
        </div>
      </div>
      {Object.keys(selectCustomerData).length > 0 ? (
        <div
          style={{
            background: "#fff",
            height: "100vh",
            width: "31%",
            position: "relative",
            fontSize: "1vw",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1vh 1vw 0 1vw",
            }}
          >
            <span
              style={{
                fontWeight: 600,
                fontSize: "1.5em",
                color: "#0F0718",
                fontFamily: "Inter",
              }}
            >
              Guest Details
            </span>
            <span style={{ fontWeight: 500, fontSize: "1em", color: "#929098" }}>Tue, 1 Aug 2023</span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0 1vw 0 0",
            }}
          >
            <span
              style={{
                padding: "0 1vw",
                fontSize: "1.25em",
                color: "#0F0718",
                fontWeight: 500,
              }}
            >
              {selectedTable?.name}
            </span>
            <span style={{ fontWeight: 500, fontSize: "1em", color: "#929098" }}>Edit</span>
          </div>
          {/* <p
            style={{
              padding: "0 1vw",
              fontSize: "1em",
              color: "#0F0718",
              fontWeight: 500,
            }}
          >
            {selectedTable?.name}
          </p> */}
          <div
            style={{
              padding: "1vh 0.6vw",
              width: "100%",
              fontSize:"1vw"
            }}
          >
            <Space
              direction="vertical"
              style={{
                width: "100%",
                background: "#F3F4F9",
                padding: "1vh 0.5vw",
                borderRadius: "10px",
                gap: "4px",

              }}
            >
              <Space style={{ width: "100%", justifyContent: "space-between" }}>
                <Text
                  style={{
                    fontSize: "1em",
                    fontWeight: 500,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  Guest name: {selectCustomerData.guestName}
                </Text>
                <Text
                  style={{
                    fontSize: "1em",
                    fontWeight: 500,
                    color: "#929098",
                    fontFamily: "Inter",
                  }}
                >
                  Order {selectCustomerData?.documentno}
                </Text>
              </Space>
              <Space style={{ width: "100%", justifyContent: "space-between" }}>
                <Text
                  style={{
                    fontSize: "1em",
                    fontWeight: 500,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  <img src={call} height={"18"} /> (+91) 9908833802
                </Text>
              </Space>
              <Space style={{ width: "100%", justifyContent: "space-between" }}>
                <Text
                  style={{
                    fontSize: "1em",
                    fontWeight: 500,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  <img src={Email} height={"18"} /> Jessicach@gmail.com
                </Text>
              </Space>
              <Space style={{ width: "100%", justifyContent: "space-between" }}>
                <Text
                  style={{
                    fontSize: "1em",
                    fontWeight: 500,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  <img src={Time} height={"18"} /> {moment(selectCustomerData.time).format("HH:mm")}
                </Text>
              </Space>
              <Space style={{ width: "100%", justifyContent: "space-between" }}>
                <Text
                  style={{
                    fontSize: "1em",
                    fontWeight: 500,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  <img src={GroupImage} height={"18"} /> {selectCustomerData.noOfPersons}
                </Text>
              </Space>
              <Space style={{ width: "100%", justifyContent: "space-between" }}>
                <Text
                  style={{
                    fontSize: "1em",
                    fontWeight: 500,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  <img src={Party} height={"18"} /> Birthday Party
                </Text>
              </Space>
            </Space>
            {/* <div>
              <Space direction="horizontal" style={{ paddingTop: "2vh" }}>
                <Button
                  onClick={() => {
                    const isGiftCardOrLoyalty =
                      selectedPaymentMethod?.name?.toLowerCase() === "card" ||
                      selectedPaymentMethod?.name?.toLowerCase() === "cash" ||
                      selectedPaymentMethod?.name?.toLowerCase() === "credit";
                    if (isGiftCardOrLoyalty && paymentVisible) {
                      if (parseFloat(Math.abs(cart.total)) > parseFloat(cart.paid)) {
                        if (parseFloat(amount) % 0.25 !== 0 && selectedPaymentMethod?.name.toLowerCase() === "cash") {
                          {
                            // message.warning(`${t("cash_payment_error")}`);
                          }
                        } else {
                          setNumb(0);
                          if (parseFloat(Math.abs(cart.total)) <= parseFloat(cart.paid) + Math.abs(amount)) {
                            setShowOrderCompleteFlag(true);
                          }
                          // Handle successful entry, e.g., submit the amount
                          requestPayment(selectedPaymentMethod, amount);
                        }
                      } else {
                        setShowOrderCompleteFlag(true);
                        processOrder();
                      }
                    } else if (paymentVisible === false) {
                      setPaymentVisible(true);
                    }
                  }}
                  style={{
                    width: "27.8vw",
                    borderRadius: "15px",
                    backgroundColor: "#2F3856",
                    color: "#fff",
                    height: "6vh",
                    fontSize: "1em",
                    fontWeight: 500,
                  }}
                >
                  {paymentVisible ? "Add Payment" : "Pay Bill"}
                </Button>
              </Space>
            </div> */}
          </div>
          <Scrollbars autoHide style={{ height: "50vh" }}>
            <InfiniteScroll
              dataLength={cart.items.length}
              hasMore={true}
              endMessage={
                <p className="center">
                  <b>Yay! you have seen it all</b>
                </p>
              }
              style={{ overflowX: "hidden" }}
            >
              {selectedTable.items.map((item) => {
                return (
                  <div style={{ padding: "12px" }}>
                    <Row>
                      <Col span={2}>
                        <img
                          src={removeBg}
                          alt={item.name}
                          style={{
                            height: "3vh",
                            width: "1.3vw",
                            objectFit: "cover",
                          }}
                        />
                      </Col>
                      <Col
                        span={16}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "0.5vw",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "1em",
                            color: "#0F0718",
                            fontWeight: 500,
                          }}
                        >
                          {item.name}
                        </Text>
                        <Text
                          style={{
                            fontSize: "0.8em",
                            color: "#929098",
                            fontWeight: 400,
                          }}
                        >
                          {item.description}
                        </Text>
                        <Text
                          style={{
                            fontWeight: 600,
                            fontSize: "1.25em",
                            color: "#0F0718",
                          }}
                         >
                          {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                          {item.salePrice}
                          {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                        </Text>
                      </Col>
                      <Col
                        span={2}
                        offset={3}
                        style={{
                          textAlign: "center",
                          color: "#0F0718",
                          fontWeight: 500,
                          fontSize: "1em",
                        }}
                      >
                        X {item.weight}
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </InfiniteScroll>
          </Scrollbars>
          <Button
            style={{
              width: "27.8vw",
              borderRadius: "10px",
              backgroundColor: "#2F3856",
              color: "#fff",
              height: "6vh",
              fontSize: "1em",
              fontWeight: 500,
              marginLeft: "0.6vw",
            }}
          >
            Continue
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default CustomersComponent;
